@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  background-color: #fafafa !important;
} */
/* .css-1191obr-MuiPaper-root-MuiAppBar-root {
  background-color: #fafafa !important;
}
.css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
  color: #000 !important;
  border: 1px solid rgb(170, 168, 168) !important;
  border-radius: 15px !important;
}
.css-yz9k0d-MuiInputBase-input {
  background-color: rgb(182, 175, 175);
}
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0px 0px 0px 0px !important;
  border-bottom: 1px solid rgb(228, 224, 224) !important;
} */

/* .css-10mzr36-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
.css-sox5kk-MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
  border: 1px solid rgb(170, 168, 168) !important;
}
.css-1u2mxbp {
  min-height: 50px !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.03) !important;
} */


/* scroll bar style  */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.gantt-container {
  height: 45vh!important;
  overflow: scroll  !important;
}
.gantt .bar-label {
    fill: #000!important;
    
    font-size: 15px!important;
 
}
::selection {
  color: #fff;
  background: #048276;
}
.css-nnw8om-MuiTimelineItem-root:before {
  display: none !important;
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 768px) {
 .css-1lk404s-MuiTypography-root-MuiTimelineContent-root{
    text-align: left!important;
  }
}
